import React from "react";
import Navbar from "../../common/navbar/Navbar";
import Footer from "../../common/footer/Footer";
import { WhyWe } from "./WhyWe";
import { Characteristics } from "./Characteristics";
import styles from "../../../styles/about.module.css";
import CompanyGoals from "./CompanyGoals";

function About() {
  return (
    <main>
      <Navbar />

      <WhyWe />
      <Characteristics />
      <CompanyGoals />
      <Footer />
    </main>
  );
}

export default About;
