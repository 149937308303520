import React, { useState, useRef, useEffect, useCallback } from "react";
import useWindowWidth from "../../../utils/useWindowWidth";
import animationData from "../../../assets/lotties/animatedMessage.json";
import styles from "./contactus.module.css";
import images from "../../../constants/index";

function ContactUsButton() {
  const windowWidth = useWindowWidth();
  const ref = useRef(null);
  const [messangersOpened, setMessangersOpened] = useState(false);
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const toggleMessangersHandle = () => {
    setMessangersOpened((prevValue) => !prevValue);
  };

  return (
    <a
      href="https://wa.me/77019191919"
      target="_blank"
      className={`${styles.contactUsButton} fixed flex flex-col items-end justify-end`}
    >
      <div
        onClick={toggleMessangersHandle}
        className={` h-[60px] w-[60px]  bg-[#38DA56] text-white rounded-lg mt-2 cursor-pointer`}
      >
        <div className={`w-full h-full p-2.5`}>
          <img src={images.whatsapp} alt="whatsapp" />
        </div>
      </div>
    </a>
  );
}

export default ContactUsButton;
