import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoClose } from "react-icons/io5";
import { FaRegPaperPlane } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { navbar } from "../../../constants/index";
import { useSelector } from "react-redux";
import styles from "./navbar.module.css";
import useWindowWidth from "../../../utils/useWindowWidth";
import images from "../../../constants/index";
import { useDispatch } from "react-redux";
import { setLanguage } from "../../../features/language";
import { motions } from "../../../constants/index";

function Navbar(props) {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const nav = navbar.nav[language];
  const windowWidth = useWindowWidth();
  const [fullScreenNav, setFullScreenNav] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentLang, setCurrentLang] = useState(language);
  const [langOpen, setLangOpen] = useState(false);

  const handleCurrentLang = (lang) => {
    setCurrentLang(lang);
    dispatch(setLanguage(lang));
  };

  const handleOpenNav = () => {
    setOpen(!open);
  };

  const handleFullScreenNav = () => {
    setFullScreenNav(!fullScreenNav);
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);

  return (
    <div
      className={`${styles.navbarCover} w-full ${fullScreenNav && "overlay"}`}
    >
      <div
        className={`${styles.navbar} ${
          props.bottomShadow && `${styles.bottomShadow}`
        }  py-3 px-6 w-full flex ${
          props.customColor ? "bg-custom-color-50" : "bg-white"
        }`}
      >
        <div
          className={`flex flex-row items-center justify-between h-[70px] text-custom-color-900 flex-1`}
        >
          <Link to="/" className={`flex flex-row items-center`}>
            <img
              src={images.logo}
              alt="logo"
              className={`w-[70px] object-cover`}
            />
          </Link>

          {windowWidth > 1200 ? (
            <>
              <div className="flex flex-row gap-[50px] text-[1rem] font-semibold">
                {nav.map((item) => (
                  <Link to={item.link} key={item.id}>
                    {item.name}
                  </Link>
                ))}
              </div>

              <div className={`flex flex-row items-center justify-center`}>
                <motion.div
                  onClick={() => setLangOpen(!langOpen)}
                  className="flex flex-row items-center cursor-pointer relative"
                  whileTap={motions.whileTap}
                >
                  <div
                    className={`text-[1.5rem] font-bold h-[27px] w-[35px] rounded-md bg-custom-color-900 overflow-hidden cursor-pointer`}
                  >
                    <img
                      src={
                        (currentLang === "en" && images.UnitedKingdomFlag) ||
                        (currentLang === "ru" && images.RussianFlag) ||
                        (currentLang === "sk" && images.SlovakFlag)
                      }
                      alt="logo"
                      className={`w-full h-full`}
                    />
                  </div>
                  <div className="max-h-[60px] max-w-[35px] ml-2 aspect-[1/1]">
                    <img
                      src={images.arrowDown}
                      alt="logo"
                      className={` w-full aspect-[1/1] h-full object-cover`}
                    />
                  </div>
                  {langOpen && (
                    <div className="absolute mt-[120px]">
                      {currentLang !== "ru" && (
                        <motion.div
                          whileTap={motions.whileTap}
                          onClick={() => handleCurrentLang("ru")}
                          className={`mb-2 text-[1.2rem] font-bold h-[27px] w-[35px] rounded-md bg-custom-color-900 overflow-hidden cursor-pointer`}
                        >
                          <img
                            src={images.RussianFlag}
                            alt="logo"
                            className={`w-full h-full`}
                          />
                        </motion.div>
                      )}
                      {currentLang !== "sk" && (
                        <motion.div
                          whileTap={motions.whileTap}
                          onClick={() => handleCurrentLang("sk")}
                          className={`mb-2 text-[1.2rem] font-bold h-[27px] w-[35px] rounded-md bg-custom-color-900 overflow-hidden cursor-pointer`}
                        >
                          <img
                            src={images.SlovakFlag}
                            alt="logo"
                            className={`w-full h-full`}
                          />
                        </motion.div>
                      )}
                      {currentLang !== "en" && (
                        <motion.div
                          whileTap={motions.whileTap}
                          onClick={() => handleCurrentLang("en")}
                          className={`text-[1.2rem] font-bold h-[27px] w-[35px] rounded-md bg-custom-color-900 overflow-hidden cursor-pointer`}
                        >
                          <img
                            src={images.UnitedKingdomFlag}
                            alt="logo"
                            className={`w-full h-full`}
                          />
                        </motion.div>
                      )}
                    </div>
                  )}
                </motion.div>
              </div>
            </>
          ) : (
            <div className="mobileHeader">
              {!open ? (
                <div className="flex flex-row items-center justify-between">
                  <div
                    className={`flex flex-row items-center justify-center mr-4`}
                  >
                    <motion.div
                      onClick={() => setLangOpen(!langOpen)}
                      className="flex flex-row items-center cursor-pointer relative"
                      whileTap={motions.whileTap}
                    >
                      <div
                        className={`text-[1.5rem] font-bold h-[27px] w-[35px] rounded-md bg-custom-color-900 overflow-hidden cursor-pointer`}
                      >
                        <img
                          src={
                            (currentLang === "en" &&
                              images.UnitedKingdomFlag) ||
                            (currentLang === "ru" && images.RussianFlag) ||
                            (currentLang === "sk" && images.SlovakFlag)
                          }
                          alt="logo"
                          className={`w-full h-full`}
                        />
                      </div>
                      <div className="max-h-[60px] max-w-[35px] ml-2 aspect-[1/1]">
                        <img
                          src={images.arrowDown}
                          alt="logo"
                          className={`h-full w-full aspect-[1/1] object-cover`}
                        />
                      </div>
                      {langOpen && (
                        <div className="absolute mt-[120px]">
                          {currentLang !== "ru" && (
                            <motion.div
                              whileTap={motions.whileTap}
                              onClick={() => handleCurrentLang("ru")}
                              className={`mb-2 text-[1.5rem] font-bold h-[27px] w-[35px] rounded-md bg-custom-color-900 overflow-hidden cursor-pointer`}
                            >
                              <img
                                src={images.RussianFlag}
                                alt="logo"
                                className={`w-full h-full`}
                              />
                            </motion.div>
                          )}
                          {currentLang !== "sk" && (
                            <motion.div
                              whileTap={motions.whileTap}
                              onClick={() => handleCurrentLang("sk")}
                              className={`mb-2 text-[1.5rem] font-bold h-[27px] w-[35px] rounded-md bg-custom-color-900 overflow-hidden cursor-pointer`}
                            >
                              <img
                                src={images.SlovakFlag}
                                alt="logo"
                                className={`w-full h-full`}
                              />
                            </motion.div>
                          )}
                          {currentLang !== "en" && (
                            <motion.div
                              whileTap={motions.whileTap}
                              onClick={() => handleCurrentLang("en")}
                              className={`text-[1.5rem] font-bold h-[27px] w-[35px] rounded-md bg-custom-color-900 overflow-hidden cursor-pointer`}
                            >
                              <img
                                src={images.UnitedKingdomFlag}
                                alt="logo"
                                className={`w-full h-full`}
                              />
                            </motion.div>
                          )}
                        </div>
                      )}
                    </motion.div>
                  </div>
                  <div
                    onClick={handleOpenNav}
                    className="icon max-h-[60px] object-cover cursor-pointer"
                  >
                    <img
                      className="icon max-h-[45px] object-cover"
                      src={images.menu}
                      alt="menu"
                    />
                  </div>
                </div>
              ) : (
                <div className="openHeader fixed left-0 right-0 top-0 bottom-0">
                  <div className="h-full w-full bg-custom-color-50 relative">
                    <Link to="/" className={`absolute left-8 top-5`}>
                      <img
                        src={images.logo}
                        alt="logo"
                        className={`w-[70px] object-cover`}
                      />
                    </Link>
                    <div className="icon max-h-[60px] object-cover cursor-pointer absolute right-8 top-7 z-[2260]">
                      <img
                        onClick={handleOpenNav}
                        className={"w-[34px] object-cover"}
                        src={images.close}
                        alt="close"
                      />
                    </div>

                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="flex flex-col items-center justify-center gap-8 text-[2.1rem] font-semibold">
                        {nav.map((item) => (
                          <Link
                            onClick={handleOpenNav}
                            to={item.link}
                            key={item.id}
                          >
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
