import { createSlice } from "@reduxjs/toolkit";

export const initialStateValue = false;

export const CookiesSlice = createSlice({
  name: "cookies",
  initialState: {
    cookies: initialStateValue,
  },
  reducers: {
    setCookies: (state, action) => {
      state.isAccepted = action.payload;
    },
  },
});

export const { setCookies } = CookiesSlice.actions;
export default CookiesSlice.reducer;
