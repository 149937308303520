import React, { useState } from "react";
import styles from "../../styles/cookies.module.css";
import { useSelector, useDispatch } from "react-redux";
import images from "../../constants/index";
import { setCookies } from "../../features/cookies";
import { cookiesText } from "../../constants/index";

function Cookies() {
  const language = useSelector((state) => state.language.language);
  const cookiesTexts = cookiesText[language];
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const dispatch = useDispatch(); // Place useDispatch inside the component

  function acceptCookies() {
    setCookiesAccepted(true);
    dispatch(setCookies(true)); // Use dispatch function to dispatch the action
    localStorage.setItem("cookiesAccepted", true);
  }

  return (
    <div
      className={`${styles.cookiesContainer} ${cookiesAccepted && "hidden"}`}
    >
      <div className={styles.wrapper}>
        <div className={styles.cookiesWrapper}>
          <div className={styles.imageSection}>
            <img
              className={styles.cookieImage}
              src={images.cookies}
              alt="cookies"
            />
          </div>
          <div className={styles.title}>Cookies</div>
        </div>
        <div className={styles.textSection}>
          <div className={styles.text}>{cookiesTexts.text}</div>
        </div>

        <div className={styles.buttonsContainer}>
          {/* Remove () from onClick */}
          <button className={styles.AcceptButton} onClick={acceptCookies}>
            {cookiesTexts.button}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Cookies;
