import React from "react";
import { useSelector } from "react-redux";
import { WhyWeText } from "../../../constants";
import styles from "../../../styles/about.module.css";

export function Characteristics() {
  const language = useSelector((state) => state.language.language);
  const whyWeText = WhyWeText[language];

  return (
    <div className="w-full bg-custom-color-100 flex flex-column items-center justify-center mt-7">
      <div className={`${styles.wrapper} px-5 py-10`}>
        <div className="flex w-full flex-col items-center text-[2.6rem] font-extrabold ">
          <div className="characteristicsTextOne text-center">
            {whyWeText.characteristicsTextOne}
          </div>
          <div className="characteristicsTextTwo text-custom-color-900 text-center">
            {whyWeText.characteristicsTextTwo}
          </div>
        </div>
        <div className={`${styles.gridWhyWe} grid grid-cols-1 mt-10 gap-5`}>
          {whyWeText.characteristics.map((item, index) => (
            <div
              className={`${styles.characteristicsContainer} bg-white p-5 rounded-md shadow-custom-color-300 shadow-md hover:scale-[1.01] transition-all `}
              key={index}
            >
              <div
                className={`${styles.characteristicsImg} h-[30px] object-cover mb-2`}
              >
                <img
                  src={item.image.src}
                  alt={item.image.alt}
                  className={`h-[30px] object-cover`}
                />
              </div>
              <div className={`${styles.characteristicsTitle} font-bold text-[1.3rem]`}>{item.title}</div>
              <div className={`${styles.characteristicsText} `}>{item.text}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
