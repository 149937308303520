import React from "react";
import styles from "../../../styles/about.module.css";
import { CompanyGoalsText } from "../../../constants";
import { useSelector } from "react-redux";

function CompanyGoals() {
  const language = useSelector((state) => state.language.language);
  const companyGoalsText = CompanyGoalsText[language];

  return (
    <div className="companyGoalsContainer w-full flex flex-row justify-center pt-8 ">
      <div className={`${styles.wrapper}`}>
        <div className={`flex flex-col justify-center ${styles.content}`}>
          <div
            className={`${styles.imageContainer} w-full flex-1   flex items-center justify-center`}
          >
            <img
              className={`h-[300px] object-cover ${styles.imageCompany}`}
              src={companyGoalsText.image}
              alt="company"
            />
          </div>
          <div className="flex-1 w-full">
            <h1 className="flex flex-row items-center justify-center text-[2rem] font-bold mt-4">
              {companyGoalsText.title}
            </h1>
            <div className="goals mt-5">
              {companyGoalsText.goals.map((goal, index) => (
                <div key={index} className="flex flex-row items-center">
                  <li> </li>
                  <p>{goal}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyGoals;
