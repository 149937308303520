import React from "react";
import styles from "../../../styles/about.module.css";
import { WhyWeText } from "../../../constants";
import { useSelector } from "react-redux";

export function WhyWe() {
  const language = useSelector((state) => state.language.language);
  const whyWeText = WhyWeText[language];

  return (
    <div
      className={`${styles.WhyWe} w-full flex flex-col items-center justify-center`}
    >
      <div className={styles.wrapper}>
        <p className="w-full text-center  text-[2rem] my-4 font-bold">
          <span className="text-custom-color-600">{whyWeText.name}&#160;</span>
          {whyWeText.title}
        </p>
        <div>
          <div className={`text-[1.2rem]  ${styles.whyWeText}`}>
            {whyWeText.description}
          </div>
        </div>

        <div className="w-full mt-14">
          {whyWeText.benefits.map((item, index) => (
            <div className="flex flex-row items-center mb-5" key={index}>
              <div className={`max-w-[55px] object-cover mr-7`}>
                <img src={item.image.src} alt={item.image.alt} />
              </div>
              <div className={`${styles.whyWeText} font-bold text-[1.3rem]`}>
                {item.text}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
