import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import languageReducer from "./features/language";
import orderBoxReducer from "./features/orderBox";
import formsClientReducer from "./features/formsClient";
import cookiesReducer from "./features/cookies";

const store = configureStore({
  reducer: {
    language: languageReducer,
    orderBox: orderBoxReducer,
    formsClient: formsClientReducer,
    cookies: cookiesReducer,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
);
