import React, { useState, useEffect } from "react";
import images from "../../constants/index";

function ArrowUp() {
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const isHalfScrolled = scrollPosition > documentHeight / 5;
      const isTallerThanViewport = documentHeight > windowHeight * 1.5;

      setShowArrow(isHalfScrolled && isTallerThanViewport);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <a
      href="#"
      className={`bg-custom-color-900 rounded-md h-16 w-16 flex items-center justify-center fixed right-4 bottom-4 shadow-md transition-opacity duration-500 ${
        showArrow ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <img src={images.arrowUp} alt="envelope" className="h-6" />
    </a>
  );
}

export default ArrowUp;
